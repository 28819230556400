import clusterService from '@/services/cluster-service'

export default {
    state: {
        selectedCluster: parseInt(localStorage.getItem('selectedCluster')),
        availableClusters: []
    },
    mutations: {
        setAvailableClusters: (state, clusters) => {
            state.availableClusters = clusters
        },
        setSelectedCluster: (state, clusterId) => {
            state.selectedCluster = clusterId
            localStorage.setItem('selectedCluster', clusterId)
        }
    },
    actions: {
        async fetchAvailableClusters({ commit }) {
            try {
                const config = await clusterService.getAvailableClusters()
                commit('setAvailableClusters', config.data)
                return Promise.resolve()
            } catch (error) {
                return Promise.reject(error)
            }
        }
    }
}
